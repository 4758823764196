export enum ScreenType {
  XS_0 = 0, // from 0,
  SM_576 = 1, // from 576px,
  MD_768 = 2, // from 768px,
  LG_992 = 3, // from 992px,
  XL_1200 = 5, // from 1200px,
  XXL_1400 = 7, // from 1400px,
  XXXL_1920 = 8, // from 1920px,
}

export const getScreenType = (width: number) => {
  if (width >= 1920) return ScreenType.XXXL_1920;
  else if (width >= 1400) return ScreenType.XXL_1400;
  else if (width >= 1200) return ScreenType.XL_1200;
  else if (width >= 992) return ScreenType.LG_992;
  else if (width >= 768) return ScreenType.MD_768;
  else if (width >= 576) return ScreenType.SM_576;
  else return ScreenType.XS_0;
};
