import { ApiLookupBase } from '@api/types';

export const GENDER_OPTIONS: ApiLookupBase[] = [
  { Id: 0, Name: 'Мужской' },
  { Id: 1, Name: 'Женский' },
];

export const ALIGNMENT_OPTIONS: ApiLookupBase[] = [
  { Id: 0, Name: 'Законопослушный добрый' },
  { Id: 1, Name: 'Законопослушный нейтральный' },
  { Id: 2, Name: 'Законопослушный злой' },
  { Id: 3, Name: 'Нейтральный добрый' },
  { Id: 4, Name: 'Истинно нейтральный' },
  { Id: 5, Name: 'Нейтральный злой' },
  { Id: 6, Name: 'Хаотичный добрый' },
  { Id: 7, Name: 'Хаотичный нейтральный' },
];

export const ABILITIES_DICT: { [key: number]: string } = {
  0: 'Strength',
  1: 'Dexterity',
  2: 'Constitution',
  3: 'Intelligence',
  4: 'Wisdom',
  5: 'Charisma',
  6: 'Athletics',
  7: 'Acrobatics',
  8: 'SleightOfHand',
  9: 'Stealth',
  10: 'Arcana',
  11: 'History',
  12: 'Investigation',
  13: 'Nature',
  14: 'Religion',
  15: 'AnimalHandling',
  16: 'Insight',
  17: 'Medicine',
  18: 'Perception',
  19: 'Survival',
  20: 'Deception',
  21: 'Intimidation',
  22: 'Performance',
  23: 'Persuasion',
};

export const ABILITIES_DICT_RUS: { [key: number]: string } = {
  0: 'Сила',
  1: 'Ловкость',
  2: 'Телосложение',
  3: 'Интеллект',
  4: 'Мудрость',
  5: 'Харизма',
  6: 'Атлетика',
  7: 'Акробатика',
  8: 'Ловкость рук',
  9: 'Скрытность',
  10: 'Магия',
  11: 'История',
  12: 'Расследование',
  13: 'Природа',
  14: 'Религия',
  15: 'Уход за животными',
  16: 'Проницательность',
  17: 'Медицина',
  18: 'Восприятие',
  19: 'Выживание',
  20: 'Обман',
  21: 'Запугивание',
  22: 'Выступление',
  23: 'Убеждение',
};

export const ABILITIES_DICT_RUS_SHORT: { [key: number]: string } = {
  0: 'Сил',
  1: 'Лвк',
  2: 'Тел',
  3: 'Инт',
  4: 'Мдр',
  5: 'Хар',
};

export const SAVING_THROWS_DICT_RUS: { [key: number]: string } = {
  0: 'Спасбросок Силы',
  1: 'Спасбросок Ловкости',
  2: 'Спасбросок Телосложения',
  3: 'Спасбросок Интеллекта',
  4: 'Спасбросок Мудрости',
  5: 'Спасбросок Харизмы',
};
