import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs';

import { ToastrService } from '@core/services';
import { AppConstants } from '@core/app-constants';

import {
  ApiAuthToken,
  ApiUserRequest,
  ApiCampaign,
  ApiCharacter,
  ApiUser,
  ApiAuthTokenRequest,
  ApiCampaignListItem,
  ApiCharacterListItem,
  ApiSpellListItem,
  ApiSpell,
  ApiMagicItemListItem,
  ApiMagicItem,
  ApiCharacterRequest,
  ApiLookupRace,
  ApiLookupClass,
  ApiLookupSpell,
  ApiLookupRacialTrait,
  ApiLookupClassFeature,
  ApiClassListItem,
  ApiCharacterView,
  ApiAchievement,
  ApiUserUpdate,
  ApiClass,
  ApiClassFeature,
  ApiRaceListItem,
  ApiRace,
  ApiCharacterEquipment,
  ApiCharacterDescriptions,
  ApiCharacterHealthPointsAndAbilitiesRequest,
  ApiCharacterClassSpellsRequest,
  ApiCharacterRaceRequest,
  ApiCharacterClassesRequest,
  ApiCharacterConfigurationRequest,
  ApiLookupBackground,
  ApiAdminBackground,
  ApiAdminBackgroundListItem,
  ApiAdminClassListItem,
  ApiAdminClass,
  ApiAdminClassFeature,
  ApiAdminClassFeatureListItem,
  ApiAdminFeatListItem,
  ApiAdminFeat,
  ApiAdminMagicItem,
  ApiAdminMagicItemListItem,
  ApiAdminRaceListItem,
  ApiAdminRace,
  ApiAdminRacialTraitListItem,
  ApiAdminRacialTrait,
  ApiAdminSpellListItem,
  ApiAdminSpell,
  ApiAdminUserListItem,
  ApiAdminUser,
  ApiCharacterClassFeaturesRequest,
  ApiCharacterClassFeatureRequest,
  ApiCharacterClassArchetypeRequest,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private http = inject(HttpClient);
  private toastr = inject(ToastrService);

  /* Achievement */

  getAchievements() {
    return this.http.get<ApiAchievement[]>(`${AppConstants.API_URL}/achievements/current`);
  }

  /* Authentication */

  login(data: ApiAuthTokenRequest) {
    const form = new FormData();
    form.append('Email', data.Email);
    form.append('Password', data.Password);
    return this.http.post<ApiAuthToken>(`${AppConstants.API_URL}/auth/login`, form);
  }

  refreshToken(data: ApiAuthToken) {
    const options = { params: new HttpParams() };
    options.params = options.params.append('token', data.access_token);
    options.params = options.params.append('refreshToken', data.refresh_token);
    return this.http.post<ApiAuthToken>(`${AppConstants.API_URL}/auth/refresh-token`, null, options);
  }

  /* Background */

  getAdminBackgrounds() {
    return this.http.get<ApiAdminBackgroundListItem[]>(`${AppConstants.API_URL}/admin/backgrounds`);
  }

  addAdminBackground(data: ApiAdminBackground) {
    return this.http
      .post<ApiAdminBackground>(`${AppConstants.API_URL}/admin/backgrounds`, data)
      .pipe(tap(() => this.toastr.show('Предыстория добавлена')));
  }

  updateAdminBackground(data: ApiAdminBackground) {
    return this.http
      .put<ApiAdminBackground>(`${AppConstants.API_URL}/admin/backgrounds`, data)
      .pipe(tap(() => this.toastr.show('Предыстория изменена')));
  }

  getAdminBackground(id: string) {
    return this.http.get<ApiAdminBackground>(`${AppConstants.API_URL}/admin/backgrounds/${id}`);
  }

  deleteAdminBackground(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/admin/backgrounds/${id}`)
      .pipe(tap(() => this.toastr.show('Предыстория удалена')));
  }

  /* Campaign */

  getCampaigns() {
    return this.http.get<ApiCampaignListItem[]>(`${AppConstants.API_URL}/campaigns`);
  }

  addCampaign(data: ApiCampaign) {
    return this.http.post<ApiCampaign>(`${AppConstants.API_URL}/campaigns`, data);
  }

  updateCampaign(data: ApiCampaign) {
    return this.http.put<ApiCampaign>(`${AppConstants.API_URL}/campaigns`, data);
  }

  getCampaign(id: string) {
    return this.http.get<ApiCampaign>(`${AppConstants.API_URL}/campaigns/${id}`);
  }

  /* Character */

  getCharacter(id: string) {
    return this.http.get<ApiCharacter>(`${AppConstants.API_URL}/characters/${id}`);
  }

  getCharacterView(id: string) {
    return this.http.get<ApiCharacterView>(`${AppConstants.API_URL}/characters/${id}/view`);
  }

  getCharacters() {
    return this.http.get<ApiCharacterListItem[]>(`${AppConstants.API_URL}/characters`);
  }

  addCharacter(data: ApiCharacterRequest) {
    return this.http.post<ApiCharacter>(`${AppConstants.API_URL}/characters`, data);
  }

  updateCharacterEquipment(data: ApiCharacterEquipment, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/equipment`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterDescriptions(data: ApiCharacterDescriptions, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/descriptions`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterHealthPointsAndAbilities(data: ApiCharacterHealthPointsAndAbilitiesRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/health-points-and-abilities`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterClasses(data: ApiCharacterClassesRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/classes/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterClassArchetype(data: ApiCharacterClassArchetypeRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/classes/${data.ClassId}/archetype`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterClassFeatures(data: ApiCharacterClassFeaturesRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/classes/${data.ClassId}/features/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterClassFeature(data: ApiCharacterClassFeatureRequest, characterId: Guid) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/classes/${data.ClassId}/features/${data.FeatureId}`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterRace(data: { Race: ApiCharacterRaceRequest }, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/race/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterSpells(data: ApiCharacterClassSpellsRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/spells/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterConfiguration(data: ApiCharacterConfigurationRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/configuration/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  getCharacterGeneratedRaces(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupRace[]>(`${AppConstants.API_URL}/characters/generated/races`, options);
  }

  getCharacterGeneratedClasses(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupClass[]>(`${AppConstants.API_URL}/characters/generated/classes`, options);
  }

  getCharacterGeneratedSpells(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupSpell[]>(`${AppConstants.API_URL}/characters/generated/spells`, options);
  }

  getCharacterGeneratedClassFeatures(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    const url = `${AppConstants.API_URL}/characters/generated/class-features`;
    return this.http.get<ApiLookupClassFeature[]>(url, options);
  }

  getCharacterGeneratedRacialTraits(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupRacialTrait[]>(`${AppConstants.API_URL}/characters/generated/racial-traits`, options);
  }

  getCharacterGeneratedBackgrounds(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupBackground[]>(`${AppConstants.API_URL}/characters/generated/backgrounds`, options);
  }

  recalculateCharacterMaxHealthPoints(characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/recalculate/max-health-points`;
    return this.http.put<ApiCharacter>(url, { Id: characterId });
  }

  recalculateCharacterAbilities(characterId: string) {
    const url = `${AppConstants.API_URL}/characters/${characterId}/recalculate/abilities`;
    return this.http.put<ApiCharacter>(url, { Id: characterId });
  }

  /* Class */

  getClasses() {
    return this.http.get<ApiClassListItem[]>(`${AppConstants.API_URL}/classes`);
  }

  getClass(slug: string) {
    return this.http.get<ApiClass>(`${AppConstants.API_URL}/classes/${slug}`);
  }

  getClassFeature(slug: string) {
    return this.http.get<ApiClassFeature>(`${AppConstants.API_URL}/classes/features/${slug}`);
  }

  getAdminClasses() {
    return this.http.get<ApiAdminClassListItem[]>(`${AppConstants.API_URL}/admin/classes`);
  }

  addAdminClass(data: ApiAdminClass) {
    return this.http
      .post<ApiAdminClass>(`${AppConstants.API_URL}/admin/classes`, data)
      .pipe(tap(() => this.toastr.show('Класс добавлен')));
  }

  updateAdminClass(data: ApiAdminClass) {
    return this.http
      .put<ApiAdminClass>(`${AppConstants.API_URL}/admin/classes`, data)
      .pipe(tap(() => this.toastr.show('Класс изменен')));
  }

  getAdminClass(id: string) {
    return this.http.get<ApiAdminClass>(`${AppConstants.API_URL}/admin/classes/${id}`);
  }

  deleteAdminClass(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/admin/classes/${id}`)
      .pipe(tap(() => this.toastr.show('Класс удален')));
  }

  /* ClassFeature */

  getAdminClassFeatures() {
    return this.http.get<ApiAdminClassFeatureListItem[]>(`${AppConstants.API_URL}/admin/class-features`);
  }

  addAdminClassFeature(data: ApiAdminClassFeature) {
    return this.http
      .post<ApiAdminClassFeature>(`${AppConstants.API_URL}/admin/class-features`, data)
      .pipe(tap(() => this.toastr.show('Способность добавлена')));
  }

  updateAdminClassFeature(data: ApiAdminClassFeature) {
    return this.http
      .put<ApiAdminClassFeature>(`${AppConstants.API_URL}/admin/class-features`, data)
      .pipe(tap(() => this.toastr.show('Способность изменена')));
  }

  getAdminClassFeature(id: string) {
    return this.http.get<ApiAdminClassFeature>(`${AppConstants.API_URL}/admin/class-features/${id}`);
  }

  deleteAdminClassFeature(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/admin/class-features/${id}`)
      .pipe(tap(() => this.toastr.show('Способность удалена')));
  }

  /* Feat */

  getAdminFeats() {
    return this.http.get<ApiAdminFeatListItem[]>(`${AppConstants.API_URL}/admin/feats`);
  }

  addAdminFeat(data: ApiAdminFeat) {
    return this.http
      .post<ApiAdminFeat>(`${AppConstants.API_URL}/admin/feats`, data)
      .pipe(tap(() => this.toastr.show('Черта добавлена')));
  }

  updateAdminFeat(data: ApiAdminFeat) {
    return this.http
      .put<ApiAdminFeat>(`${AppConstants.API_URL}/admin/feats`, data)
      .pipe(tap(() => this.toastr.show('Черта изменена')));
  }

  getAdminFeat(id: string) {
    return this.http.get<ApiAdminFeat>(`${AppConstants.API_URL}/admin/feats/${id}`);
  }

  deleteAdminFeat(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/admin/feats/${id}`)
      .pipe(tap(() => this.toastr.show('Черта удалена')));
  }

  /* MagicItem */

  getMagicItems() {
    return this.http.get<ApiMagicItemListItem[]>(`${AppConstants.API_URL}/magic-items`);
  }

  getMagicItem(slug: string) {
    return this.http.get<ApiMagicItem>(`${AppConstants.API_URL}/magic-items/${slug}`);
  }

  getAdminMagicItems() {
    return this.http.get<ApiAdminMagicItemListItem[]>(`${AppConstants.API_URL}/admin/magic-items`);
  }

  addAdminMagicItem(data: ApiAdminMagicItem) {
    return this.http
      .post<ApiAdminMagicItem>(`${AppConstants.API_URL}/admin/magic-items`, data)
      .pipe(tap(() => this.toastr.show('Магический предмет добавлен')));
  }

  updateAdminMagicItem(data: ApiAdminMagicItem) {
    return this.http
      .put<ApiAdminMagicItem>(`${AppConstants.API_URL}/admin/magic-items`, data)
      .pipe(tap(() => this.toastr.show('Магический предмет изменен')));
  }

  getAdminMagicItem(id: string) {
    return this.http.get<ApiAdminMagicItem>(`${AppConstants.API_URL}/admin/magic-items/${id}`);
  }

  deleteAdminMagicItem(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/admin/magic-items/${id}`)
      .pipe(tap(() => this.toastr.show('Магический предмет удален')));
  }

  /* Race */

  getRaces() {
    return this.http.get<ApiRaceListItem[]>(`${AppConstants.API_URL}/races`);
  }

  getRace(slug: string) {
    return this.http.get<ApiRace>(`${AppConstants.API_URL}/races/${slug}`);
  }

  getAdminRaces() {
    return this.http.get<ApiAdminRaceListItem[]>(`${AppConstants.API_URL}/admin/races`);
  }

  addAdminRace(data: ApiAdminRace) {
    return this.http
      .post<ApiAdminRace>(`${AppConstants.API_URL}/admin/races`, data)
      .pipe(tap(() => this.toastr.show('Раса добавлена')));
  }

  updateAdminRace(data: ApiAdminRace) {
    return this.http
      .put<ApiAdminRace>(`${AppConstants.API_URL}/admin/races`, data)
      .pipe(tap(() => this.toastr.show('Раса изменена')));
  }

  getAdminRace(id: string) {
    return this.http.get<ApiAdminRace>(`${AppConstants.API_URL}/admin/races/${id}`);
  }

  deleteAdminRace(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/admin/races/${id}`)
      .pipe(tap(() => this.toastr.show('Раса удалена')));
  }

  /* RacialTrait */

  getAdminRacialTraits() {
    return this.http.get<ApiAdminRacialTraitListItem[]>(`${AppConstants.API_URL}/admin/racial-traits`);
  }

  addAdminRacialTrait(data: ApiAdminRacialTrait) {
    return this.http
      .post<ApiAdminRacialTrait>(`${AppConstants.API_URL}/admin/racial-traits`, data)
      .pipe(tap(() => this.toastr.show('Особенность добавлена')));
  }

  updateAdminRacialTrait(data: ApiAdminRacialTrait) {
    return this.http
      .put<ApiAdminRacialTrait>(`${AppConstants.API_URL}/admin/racial-traits`, data)
      .pipe(tap(() => this.toastr.show('Особенность изменена')));
  }

  getAdminRacialTrait(id: string) {
    return this.http.get<ApiAdminRacialTrait>(`${AppConstants.API_URL}/admin/racial-traits/${id}`);
  }

  deleteAdminRacialTrait(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/admin/racial-traits/${id}`)
      .pipe(tap(() => this.toastr.show('Особенность удалена')));
  }

  /* Spell */

  getSpells() {
    return this.http.get<ApiSpellListItem[]>(`${AppConstants.API_URL}/spells`);
  }

  getSpell(slug: string) {
    return this.http.get<ApiSpell>(`${AppConstants.API_URL}/spells/${slug}`);
  }

  getAdminSpells() {
    return this.http.get<ApiAdminSpellListItem[]>(`${AppConstants.API_URL}/admin/spells`);
  }

  addAdminSpell(data: ApiAdminSpell) {
    return this.http
      .post<ApiAdminSpell>(`${AppConstants.API_URL}/admin/spells`, data)
      .pipe(tap(() => this.toastr.show('Заклиание добавлено')));
  }

  updateAdminSpell(data: ApiAdminSpell) {
    return this.http
      .put<ApiAdminSpell>(`${AppConstants.API_URL}/admin/spells`, data)
      .pipe(tap(() => this.toastr.show('Заклиание изменено')));
  }

  getAdminSpell(id: string) {
    return this.http.get<ApiAdminSpell>(`${AppConstants.API_URL}/admin/spells/${id}`);
  }

  deleteAdminSpell(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/admin/spells/${id}`)
      .pipe(tap(() => this.toastr.show('Заклиание удалено')));
  }

  /* User */

  register(data: ApiUserRequest) {
    return this.http.post<ApiUser>(`${AppConstants.API_URL}/users`, data);
  }

  updateCurrentUser(data: ApiUserUpdate) {
    return this.http.put<ApiUser>(`${AppConstants.API_URL}/users/current`, data);
  }

  getCurrentUser() {
    return this.http.get<ApiUser>(`${AppConstants.API_URL}/users/current`);
  }

  getUser(id: string) {
    return this.http.get<ApiUser>(`${AppConstants.API_URL}/users/${id}`);
  }

  getAdminUsers() {
    return this.http.get<ApiAdminUserListItem[]>(`${AppConstants.API_URL}/admin/users`);
  }

  updateAdminUser(data: ApiAdminUser) {
    return this.http.put<ApiAdminUser>(`${AppConstants.API_URL}/admin/users`, data);
  }
}
