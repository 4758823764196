import { computed, Injectable } from '@angular/core';
import { map } from 'rxjs';

import { ApiLookupService } from '@api/services';
import {
  ApiLookupAreaType,
  ApiLookupCastingTimeType,
  ApiLookupSavingThrowType,
  ApiLookupClass,
  ApiLookupCopyrightHolders,
  ApiLookupDice,
  ApiLookupDurationTimeType,
  ApiLookupDurationType,
  ApiLookupEffectType,
  ApiLookupLevel,
  ApiLookupRangeType,
  ApiLookupSpellSchoolType,
  ApiLookupTranslators,
  ApiLookupMagicItemCategoryType,
  ApiLookupMagicItemRarityType,
  ApiLookupMagicItemChargesResetType,
  ApiLookupItemType,
  ApiLookupLanguageType,
} from '@api/types';
import { AppArraySource } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class LookupDataService {
  levels = new AppArraySource<ApiLookupLevel>();
  classes = new AppArraySource<ApiLookupClass>();
  сharacterAbilitiesAndSkills = new AppArraySource<ApiLookupSavingThrowType>();
  abilityTypes$ = this.сharacterAbilitiesAndSkills.data$.pipe(map(data => data.filter(item => item.Parent === null)));
  abilityTypes = computed(() => this.сharacterAbilitiesAndSkills.data().filter(item => item.Parent === null));
  skillTypes$ = this.сharacterAbilitiesAndSkills.data$.pipe(
    map(data => data.filter(item => typeof item.Parent === 'number'))
  );
  skillTypes = computed(() => this.сharacterAbilitiesAndSkills.data().filter(item => typeof item.Parent === 'number'));
  dices = new AppArraySource<ApiLookupDice>();
  spellSchoolTypes = new AppArraySource<ApiLookupSpellSchoolType>();
  effectTypes = new AppArraySource<ApiLookupEffectType>();
  durationTypes = new AppArraySource<ApiLookupDurationType>();
  durationTimeTypes = new AppArraySource<ApiLookupDurationTimeType>();
  castingTimeTypes = new AppArraySource<ApiLookupCastingTimeType>();
  rangeTypes = new AppArraySource<ApiLookupRangeType>();
  areaTypes = new AppArraySource<ApiLookupAreaType>();
  copyrightHolders = new AppArraySource<ApiLookupCopyrightHolders>();
  translators = new AppArraySource<ApiLookupTranslators>();
  magicItemCategoryTypes = new AppArraySource<ApiLookupMagicItemCategoryType>();
  magicItemRarityTypes = new AppArraySource<ApiLookupMagicItemRarityType>();
  magicItemResetChargesTypes = new AppArraySource<ApiLookupMagicItemChargesResetType>();
  itemTypes = new AppArraySource<ApiLookupItemType>();
  languageTypes = new AppArraySource<ApiLookupLanguageType>();

  constructor(private apiLookupService: ApiLookupService) {}

  loadData() {
    this.apiLookupService.getLevels().subscribe(data => this.levels.setData(data));
    this.apiLookupService.getClasses().subscribe(data => this.classes.setData(data));
    this.apiLookupService.getSavingThrowTypes().subscribe(data => this.сharacterAbilitiesAndSkills.setData(data));
    this.apiLookupService.getDiceTypes().subscribe(data => this.dices.setData(data));
    this.apiLookupService.getSpellSchoolTypes().subscribe(data => this.spellSchoolTypes.setData(data));
    this.apiLookupService.getEffectTypes().subscribe(data => this.effectTypes.setData(data));
    this.apiLookupService.getDurationType().subscribe(data => this.durationTypes.setData(data));
    this.apiLookupService.getDurationTimeType().subscribe(data => this.durationTimeTypes.setData(data));
    this.apiLookupService.getCastingTimeType().subscribe(data => this.castingTimeTypes.setData(data));
    this.apiLookupService.getRangeType().subscribe(data => this.rangeTypes.setData(data));
    this.apiLookupService.getAreaType().subscribe(data => this.areaTypes.setData(data));
    this.apiLookupService.getCopyrightHolders().subscribe(data => this.copyrightHolders.setData(data));
    this.apiLookupService.getTranslators().subscribe(data => this.translators.setData(data));
    this.apiLookupService.getMagicItemCategoryTypes().subscribe(data => this.magicItemCategoryTypes.setData(data));
    this.apiLookupService.getMagicItemRarityTypes().subscribe(data => this.magicItemRarityTypes.setData(data));
    this.apiLookupService
      .getMagicItemResetChargesTypes()
      .subscribe(data => this.magicItemResetChargesTypes.setData(data));
    this.apiLookupService.getItemTypes().subscribe(data => this.itemTypes.setData(data));
    this.apiLookupService.getLanguageTypes().subscribe(data => this.languageTypes.setData(data));
  }
}
